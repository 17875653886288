import { Controller } from '@hotwired/stimulus'
import { get } from '../../utils/ajax'
import { serialize } from '../../utils/form'

export default class extends Controller {
  static targets = ['submitButton', 'value']
  static values = { url: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onFormSubmit(event) {
    if (this.disabled) {
      event.preventDefault()
      return false
    }
  }

  onQuantityChange(event) {
    this.update()
  }

  /* ----- FUNCTIONS ----- */

  async update() {
    this.disabled = true
    this.submitButtonTarget.disabled = true

    const result = await get(this.urlValue, serialize(this.element), 'html')

    this.valueTargets.forEach(oldElement => {
      const id = oldElement.dataset.valueId
      const newElement = result.querySelector(`[data-value-id="${id}"]`)

      if (newElement) {
        oldElement.replaceWith(newElement)
      }
    })

    this.disabled = false
    this.submitButtonTarget.disabled = false
  }

  /* ----- GETTERS / SETTERS ----- */
}
