import { Controller } from '@hotwired/stimulus'
import { useControllerMixin } from 'scripts/mixins/controller'
import { addClickOutsideListener, isClickingOutside } from 'scripts/utils/event'

export default class extends Controller {
  static classes = ['reduce']
  static targets = ['message', 'dropdown', 'logo', 'menu', 'menuTrigger']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    useControllerMixin(this)

    this.scrollTrigger = this.element.offsetHeight / 2
    this.setScrollState()

    if (this.hasMenuTarget) {
      document.addEventListener('click', event => {
        if (isClickingOutside(event.target, this.menuTarget) && isClickingOutside(event.target, this.menuTriggerTarget)) {
          this.toggleMenu(false)
        }
      })
    }

    this.dropdownTargets.forEach(dropdown => {
      const dropdownToggle = dropdown.closest('[data-controller="toggle"]')

      addClickOutsideListener(dropdownToggle, event => {
        const toggleController = this.getControllerForElement(dropdownToggle, 'toggle')

        if (toggleController) {
          toggleController.toggle(true)
        }
      })
    })
  }

  /* ----- ACTION EVENTS ----- */

  handleToggleMenu(event) {
    this.toggleMenu()
  }

  onWindowScroll(event) {
    this.setScrollState()
  }

  /* ----- FUNCTIONS ----- */

  setScrollState() {
    if (window.scrollY > this.scrollTrigger) {
      this.element.classList.add(this.reduceClasses)

      if (this.hasMessageTarget) {
        this.messageTarget.classList.remove(...this.messageTarget.dataset.headerExpandClass.split(' '))
        this.messageTarget.classList.add(...this.messageTarget.dataset.headerReduceClass.split(' '))
      }

      if (this.hasLogoTarget) {
        this.logoTarget.classList.add(...this.logoTarget.dataset.headerReduceClass.split(' '))
      }

      if (this.hasMenuTarget) {
        this.menuTarget.classList.add(...this.menuTarget.dataset.headerReduceClass.split(' '))
      }
    } else {
      this.element.classList.remove(this.reduceClasses)

      if (this.hasMessageTarget) {
        this.messageTarget.classList.remove(...this.messageTarget.dataset.headerReduceClass.split(' '))
        this.messageTarget.classList.add(...this.messageTarget.dataset.headerExpandClass.split(' '))
      }

      if (this.hasLogoTarget) {
        this.logoTarget.classList.remove(...this.logoTarget.dataset.headerReduceClass.split(' '))
      }

      if (this.hasMenuTarget) {
        this.menuTarget.classList.remove(...this.menuTarget.dataset.headerReduceClass.split(' '))
      }
    }
  }

  toggleMenu(force) {
    if (this.hasMenuTarget) {
      this.menuTarget.dataset.headerOpenClass.split(' ').forEach(cl => {
        this.menuTarget.classList.toggle(cl, force)
      })
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
