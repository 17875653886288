import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['countriesField', 'genresSelect', 'pcInputsWrapper', 'scenographyInputsWrapper', 'specificLbeFuncInputsWrapper', 'userInputsWrapper']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onAvailableWorldwideRadioChange(event) {
    this.countriesFieldTarget.classList.toggle('hidden', event.currentTarget.value != 'false')
  }

  onMainGenreSelectChange(event) {
    this.updateGenresSelectOption(event.currentTarget.value)
  }

  onPcRadioChange(event) {
    this.pcInputsWrapperTargets.forEach(wrapper => {
      wrapper.classList.toggle('hidden', event.currentTarget.value != 'true')
    })
  }

  onScenographyRadioChange(event) {
    this.scenographyInputsWrapperTargets.forEach(wrapper => {
      wrapper.classList.toggle('hidden', event.currentTarget.value != 'true')
    })
  }

  onSingleUserRadioChange(event) {
    this.userInputsWrapperTarget.classList.toggle('hidden', event.currentTarget.value == 'true')
  }

  onSpecificLbeFuncRadioChange(event) {
    this.specificLbeFuncInputsWrapperTarget.classList.toggle('hidden', event.currentTarget.value != 'true')
  }

  /* ----- FUNCTIONS ----- */

  updateGenresSelectOption(mainGenreValue) {
    const tomSelect = this.genresSelectTarget.tomselect
    Object.values(tomSelect.options).forEach(opt => {
      if (opt.value === mainGenreValue) tomSelect.removeItem(mainGenreValue)
      opt.disabled = opt.value === mainGenreValue
      tomSelect.updateOption(opt.value, opt)
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}
