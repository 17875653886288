import { Controller } from '@hotwired/stimulus'
import { get } from '../../utils/ajax'

export default class extends Controller {
  static targets = [ 'build', 'form']

  /* ----- LIFECYCLE CALLBACKS ----- */

 /* ----- ACTION EVENTS ----- */

 onExperienceChange(event) {
    this.update(event)
  }

  /* ----- FUNCTIONS ----- */

  async update(event) {
    const url_ajax = this.formTarget.action + "?search[experience]=" + event.target.value
    const result = await get(url_ajax, 'html')

    const id = this.buildTarget.id
    const newElement = result.querySelector('#'+ id)

    if (newElement) {
      this.buildTarget.replaceWith(newElement)
    }

  }

  /* ----- GETTERS / SETTERS ----- */
}
