import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox'
import { insertAfter } from '../../utils/application'

export default class extends Controller {
  static values = { media: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    const tacServices = ['vimeo', 'youtube']

    tacServices.forEach(service => {
      if (this.mediaValue === service) {
        document.addEventListener(`${service}_added`, e => {
          if (tarteaucitron.state[service] === true) {
            this.setup()
          } else {
            this.fallback(service)
          }
        })

        document.addEventListener(`${service}_allowed`, e => {
          if (this.fallbackElement) {
            this.fallbackElement.remove()
          }

          if (!this.lightbox) {
            this.setup()
          }
        })

        document.addEventListener(`${service}_disallowed`, e => {
          if (!this.fallbackElement) {
            this.fallback(service)
          }

          if (this.lightbox) {
            this.destroy()
          }
        })
      }
    })
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  setup() {
    this.lightbox = GLightbox({ selector: '[data-controller~="lightbox"]' })
  }

  destroy() {
    this.lightbox.destroy()
  }

  fallback(id) {
    this.fallbackElement = document.createElement('div')
    this.fallbackElement.setAttribute('class', this.element.getAttribute('class'))
    this.fallbackElement.innerHTML = tarteaucitron.engage(id)
    insertAfter(this.fallbackElement, this.element)
  }

  /* ----- GETTERS / SETTERS ----- */
}
