import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['anchor', 'button', 'link', 'panel']
  static classes = ['hidden']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '')

      this.showPanel(id)
    }
  }

  buttonTargetConnected(element) {
    if (element.dataset.formCheck) {
      const errorIcon = element.querySelector('.error-icon')
      const panel = document.getElementById(element.dataset.tabsComponentPanelParam)

      if (errorIcon && panel && panel.querySelector('.is-invalid')) {
        errorIcon.classList.remove('hidden')
      }
    }
  }

  /* ----- ACTION EVENTS ----- */

  onAnchorClick(event) {
    this.goToPanel(event.params.panel)
  }

  onButtonClick(event) {
    this.showPanel(event.params.panel)
  }

  onExternalButtonClick(event) {
    this.showPanel(event.params.panel)
  }

  /* ----- FUNCTIONS ----- */

  activeButton(button, active = true) {
    const color = button.dataset.color
    const bg = button.dataset.bg
    const activeColor = button.dataset.activeColor
    const activeBg = button.dataset.activeBg

    if (active) {
      button.classList.remove(...[color, bg])
      button.classList.add(...[activeColor, activeBg])
    } else {
      button.classList.remove(...[activeColor, activeBg])
      button.classList.add(...[color, bg])
    }
  }

  showPanel(id) {
    this.panelTargets.forEach(panel => {
      const isSelected = panel.id === id
      const button = this.buttonTargets.find(button => button.dataset.tabsComponentPanelParam === panel.id)

      panel.classList.toggle(this.hiddenClass, !isSelected)

      if (button) {
        this.activeButton(button, isSelected)
      }
    })
  }

  goToPanel(id, smooth = true) {
    const panel = document.getElementById(id)
    const offset = document.querySelector('header').offsetHeight
    const position = panel.offsetTop - offset

    window.scrollTo({
      top: position,
      behavior: smooth ? 'smooth' : 'auto'
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}
