import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['select']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.setup()
  }

  /* ----- FUNCTIONS ----- */

  setup() {
    new TomSelect(this.selectTarget, this.options)
  }

  /* ----- GETTERS / SETTERS ----- */

  get type() {
    if (this.selectTarget.hasAttribute('multiple')) {
      return 'multiple'
    } else {
      return 'single'
    }
  }

  get options() {
    const plugins = []
    const options = this.selectTarget.dataset.advancedSelectOptions ? JSON.parse(this.selectTarget.dataset.advancedSelectOptions) : {}

    if (this.type === 'single') {
      plugins.push('dropdown_input')
    }

    if (this.type === 'multiple') {
      plugins.push('remove_button')
    }

    return {
      plugins: plugins,
      allowEmptyOption: true,
      ...options
    }
  }
}
