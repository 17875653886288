const camelize = str => str.toLowerCase().replace(/[-_\s.]+(.)?/g, (m, chr) => chr.toUpperCase())

const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

const insertAfter = (el, reference) => {
  reference.parentNode.insertBefore(el, reference)
  reference.parentNode.insertBefore(reference, el)
}

const isElementInViewport = el => {
  const rect = el.getBoundingClientRect()
  const windowHeight = window.innerHeight || document.documentElement.clientHeight
  const windowWidth = window.innerWidth || document.documentElement.clientWidth
  const verticallyInView = rect.top <= windowHeight && rect.top + rect.height >= 0
  const horizontallyInView = rect.left <= windowWidth && rect.left + rect.width >= 0

  return verticallyInView && horizontallyInView
}

const isVisible = el => {
  if (!el) return false
  if (!el.offsetWidth && !el.offsetHeight) return false
  if (!el.getClientRects().length) return false
  if (el.display === 'none') return false
  if (el.style.visibility === 'hidden') return false
  return true
}

const language = document.documentElement.lang

const parseDOM = (data, type = 'text/html') => new DOMParser().parseFromString(data, type)

const serializeDOM = data => new XMLSerializer().serializeToString(data)

const viewportOffset = el => {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export { camelize, focusableSelector, insertAfter, isElementInViewport, isVisible, language, parseDOM, serializeDOM, viewportOffset }
