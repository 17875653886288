import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']
  static classes = ['state']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.class = this.hasStateClass ? this.stateClass : 'hidden'
  }

  /* ----- ACTION EVENTS ----- */

  handleToggle(event) {
    this.toggle()
  }

  handleAdd(event) {
    this.toggle(true)
  }

  handleRemove(event) {
    this.toggle(false)
  }

  /* ----- FUNCTIONS ----- */

  toggle(force) {
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class, force)
      const event = new CustomEvent('toggle', { detail: { toggle: item.classList.contains(this.class) } })
      item.dispatchEvent(event)
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}
