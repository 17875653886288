import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation } from 'swiper'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    Swiper.use([Navigation])

    new Swiper(this.element, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 'auto',
      spaceBetween: 16,
    })
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
