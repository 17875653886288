import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['invoiceOtherAddressWrapper', 'paymentCreditCardWrapper', 'paymentPurchaseOrderWrapper']

  onInvoiceTypeRadioChange(event) {
    this.invoiceOtherAddressWrapperTargets.forEach(wrapper => {
      wrapper.classList.toggle('hidden', event.currentTarget.value != 'other_address')
    })
  }

  onPaymentTypeRadioChange(event) {
    this.paymentCreditCardWrapperTargets.forEach(wrapper => {
      wrapper.classList.toggle('hidden', event.currentTarget.value == 'purchase_order')
    })

    this.paymentPurchaseOrderWrapperTargets.forEach(wrapper => {
      wrapper.classList.toggle('hidden', event.currentTarget.value != 'purchase_order')
    })
  }
}
