const addClickOutsideListener = (el, callback) => {
  document.addEventListener('click', event => {
    if (isClickingOutside(event.target, el)) {
      callback(event)
    }
  })
}

const isClickingOutside = (target, el) => el !== target && !el.contains(target)

export { addClickOutsideListener, isClickingOutside }
