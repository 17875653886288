import { Controller } from '@hotwired/stimulus'
import { handleResponse } from '../../utils/ajax'

export default class extends Controller {
  static targets = ['form', 'value']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onAjaxSuccess(event) {
    handleResponse(event, fragment => {
      this.valueTargets.forEach(oldElement => {
        const id = oldElement.dataset.valueId
        const newElement = fragment.querySelector(`[data-value-id="${id}"]`)

        if (newElement) {
          oldElement.replaceWith(newElement)
        }
      })
    })
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
