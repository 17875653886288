import '@hotwired/turbo-rails'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import Confirm from 'scripts/utils/confirm'

import 'stylesheets/application/style'

Confirm.init({ // Confirm init always before Rails start
  buttonsStyling: false
})

Rails.start()
ActiveStorage.start()

window.Images = require.context('images', true)

window.Stimulus = Application.start()
const context = require.context('scripts/application/controllers', true, /\.js$/)
const contextComponents = require.context('../../components', true, /_controller\.js$/)

Stimulus.load(
  definitionsFromContext(context)
)
Stimulus.load(
  definitionsFromContext(contextComponents)
)
