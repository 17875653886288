import Rails from '@rails/ujs'

const queryString = data => {
  return new URLSearchParams(data).toString()
}

const ajax = async config => {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      ...config,
      success: data => {
        resolve(data)
      },
    })
  })
}

const get = async (url, data = {}, dataType = 'script') => {
  return await ajax({ url, data: queryString(data), type: 'GET', dataType: dataType })
}

const post = async (url, data = {}, dataType = 'script') => {
  return await ajax({ url, data: queryString(data), type: 'POST', dataType: dataType })
}

const update = post

const put = async (url, data = {}, dataType = 'script') => {
  return await ajax({ url, data, type: 'PUT', dataType: dataType })
}

const remove = async (url, data = {}, dataType = 'script') => {
  return await ajax({ url, data, type: 'DELETE', dataType: dataType })
}

const handleResponse = (event, callback) => {
  const [, , xhr] = event.detail
  const fragment = documentFragment(xhr.response)
  callback(fragment)
}

const documentFragment = data => {
  const el = document.createElement('template')
  el.innerHTML = data
  return el.content
}

export { ajax, get, post, update, put, remove, handleResponse }
