import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    if (typeof mtcaptcha !== 'undefined') {
      mtcaptcha.renderUI()
    }
  }

  disconnect() {
    if (typeof mtcaptcha !== 'undefined') {
      mtcaptcha.remove()
    }
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
