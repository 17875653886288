export class ControllerMixin {
  constructor(controller) {
    /* ----- ASSIGN FUNCTIONS ----- */
    Object.assign(controller, {
      hasControllerForElement: this.hasControllerForElement,
      getControllerForElement: this.getControllerForElement,
      hasParentController: this.hasParentController,
      getParentController: this.getParentController,
      hasController: this.hasController,
      getController: this.getController,
      getControllers: this.getControllers,
      controllersFilterScope: this.controllersFilterScope,
    })
  }

  /* ----- FIND CONTROLLER FOR AN ELEMENT ----- */

  hasControllerForElement(target, targetedIdentifier) {
    return !!this.getControllerForElement(target, targetedIdentifier)
  }

  getControllerForElement(target, targetedIdentifier) {
    return this.application.getControllerForElementAndIdentifier(target, targetedIdentifier)
  }

  /* ----- FIND PARENT CONTROLLER FROM ELEMENT ----- */

  hasParentController(targetedIdentifier) {
    return !!this.getParentController(targetedIdentifier)
  }

  getParentController(targetedIdentifier, { element: element }) {
    const target = element.parentElement.closest(`[data-controller*="${targetedIdentifier}"]`)
    return this.getControllerForElement(target, targetedIdentifier)
  }

  /* ----- FIND CONTROLLERS GLOBALLY IN APPLICATION ----- */

  hasController(targetedIdentifier, { targetedId = false, targetedClass = false } = {}) {
    return !!this.getController(targetedIdentifier, {
      targetedId: targetedId,
      targetedClass: targetedClass,
    })
  }

  getController(targetedIdentifier, { targetedId = false, targetedClass = false } = {}) {
    return this.application.controllers.find(
      this.controllersFilterScope(targetedIdentifier, {
        targetedId: targetedId,
        targetedClass: targetedClass,
      })
    )
  }

  getControllers(targetedIdentifier, { targetedClass = false } = {}) {
    return this.application.controllers.filter(
      this.controllersFilterScope(targetedIdentifier, {
        targetedClass: targetedClass,
      })
    )
  }

  controllersFilterScope(targetedIdentifier, { targetedId = false, targetedClass = false } = {}) {
    return ({
      context: {
        identifier,
        element: { id, classList },
      },
    }) => {
      let condition = identifier === targetedIdentifier

      if (targetedId) {
        condition = identifier === targetedIdentifier && id === targetedId
      } else if (targetedClass) {
        condition = identifier === targetedIdentifier && classList.contains(targetedClass)
      }

      return condition
    }
  }
}

export const useControllerMixin = (controller) => {
  return new ControllerMixin(controller)
}
