import { Controller } from '@hotwired/stimulus'
import { language } from '../../utils/application'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.element.textContent = ''

    const script = document.createElement('script')
    script.src = this.scriptUrl
    this.element.appendChild(script)

    script.onload = () => {
      hbspt.forms.create(this.options)
    }
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */

  get scriptUrl() {
    if (language === 'fr') {
      return '//js-eu1.hsforms.net/forms/v2.js'
    }

    return '//js-eu1.hsforms.net/forms/shell.js'
  }

  get options() {
    if (language === 'fr') {
      return {
        region: 'eu1',
        portalId: '25523190',
        formId: '21aefd72-b8b9-4a85-81dd-8175e61fa897',
        version: 'V2_PRERELEASE'
      }
    }

    return {
      region: 'eu1',
      portalId: '25523190',
      formId: '1308125b-5d55-4961-a5a8-fd9bf8923290'
    }
  }
}
