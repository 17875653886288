import { Controller } from '@hotwired/stimulus'
import Swiper, { Autoplay, Pagination } from 'swiper'

export default class extends Controller {
  static targets = ['slide']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    Swiper.use([Autoplay, Pagination])

    new Swiper(this.element, {
      autoplay: {
        delay: 7000,
      },
      loop: this.slideTargets.length > 1,
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      speed: 1000,
      slidesPerView: 1,
    })
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
