const EN = {
  confirm: {
    text: 'A file is being upload. Leaving the page will cancel the upload.',
    title: 'Are you sure?'
  },
  selected_file: 'file selected',
  selected_files: 'files selected',
}

export { EN }
