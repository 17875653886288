import { Controller } from '@hotwired/stimulus'
import gsap from 'gsap'

export default class extends Controller {
  static targets = ['item']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.itemTargets.forEach(item => {
      item.addEventListener('toggle', event => this.updateItemToggleElementsState(item, event.detail.toggle))
    })
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  updateItemToggleElementsState(item, force) {
    const toggleElements = item.querySelectorAll('[data-item-open-class]')

    toggleElements.forEach(element => {
      element.dataset.itemOpenClass.split(' ').forEach(cl => {
        element.classList.toggle(cl, force)
      })

      if (element.dataset.itemContent) {
        if (element.classList.contains('is-open')) {
          gsap.timeline().to(element, { height: 'auto', duration: 0.3 })
        } else {
          gsap.timeline().to(element, { height: 0, duration: 0.3 })
        }
      }
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}
