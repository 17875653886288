import Rails from '@rails/ujs'
import Swal from 'sweetalert2'

import { language } from 'scripts/utils/application'
import { EN } from 'scripts/admin/locales/confirm/en'
import { FR } from 'scripts/admin/locales/confirm/fr'
import { ZH } from 'scripts/admin/locales/confirm/zh'

class Confirm {
  init(options = {}) {
    Rails.delegate(document, '[data-confirm]', 'click', e => {
      if (!this.allowAction(e.target, options)) Rails.stopEverything(e)
    })
  }

  allowAction(el, options) {
    const target = el.dataset.confirm ? el : el.closest('[data-confirm]')

    this.show(target, options)
    return false
  }

  async show(target, options) {
    const result = await Swal.fire({
      title: target.dataset.confirmTitle,
      text: target.dataset.confirmText,
      html: target.dataset.confirmHtml,
      showCancelButton: !target.dataset.hideCancelButton,
      showConfirmButton: !target.dataset.hideConfirmButton,
      cancelButtonColor: '#cccccc',
      confirmButtonColor: '#000000',
      cancelButtonText: target.dataset.cancelButtonText || this.locale.cancelButtonText,
      confirmButtonText: target.dataset.confirmButtonText || this.locale.confirmButtonText,
      ...options
    })

    if (result.isConfirmed) {
      target.removeAttribute('data-confirm')
      target.click()
      target.dataset.confirm = true
    }
  }

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      en: EN,
      fr: FR,
      zh: ZH,
    }
  }
}

const instance = new Confirm()
Object.freeze(instance)

export default instance
