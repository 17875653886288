import { Controller } from '@hotwired/stimulus'
import * as Turbo from "@hotwired/turbo"
import MicroModal from 'micromodal'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    MicroModal.init(this.config)
    this.show()
  }

  disconnect() {
    this.element.remove()
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  show() {
    MicroModal.show(this.element.id, this.config)
  }

  hide() {
    MicroModal.close(this.element.id)
  }

  refresh() {
    this.hide()
    Turbo.visit(window.location.href)
  }

  /* ----- GETTERS / SETTERS ----- */

  get config() {
    return {
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      closeTrigger: 'data-micromodal-close'
    }
  }
}
