import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import StatusBar from '@uppy/status-bar'
import French from '@uppy/locales/lib/fr_FR'

import { language } from 'scripts/utils/application'
import Confirm from 'scripts/utils/confirm'
import { EN } from 'scripts/admin/locales/file/en'
import { FR } from 'scripts/admin/locales/file/fr'

export default class extends Controller {
  static targets = ['hiddenInput', 'hiddenSignedInput', 'nameInput', 'removeInput', 'statusBar']

  /* ----- LIFECYCLE CALLBACKS ----- */

  hiddenInputTargetConnected(element) {
    if (element.dataset.multipart) {
      this.setMultipartUpload()
    }
  }

  /* ----- ACTION EVENTS ----- */

  onGetNameClick(event) {
    this.getName(event.currentTarget)
  }

  onRemoveClick(event) {
    event.preventDefault()
    this.remove(event)
  }

  /* ----- FUNCTIONS ----- */

  getName(target) {
    if (target.files.length > 0) {
      let name = target.files[0].name

      if (this.type === 'multiple') {
        const translation = target.files.length > 1 ? this.locale.selected_files : this.locale.selected_file
        name = target.files.length + ' ' + translation
      }

      this.nameInputTarget.value = name
    }
  }

  remove(event) {
    const parent = event.currentTarget.closest('a')

    if (parent && parent.dataset.attachmentId) {
      parent.nextElementSibling.remove()
      parent.remove()
    } else {
      this.removeInputTarget.disabled = false
      this.nameInputTarget.value = ''
    }
  }

  displayConfirm(event) {
    event.preventDefault()

    const locales = {
      en: EN,
      fr: FR
    }

    const link = document.createElement('a')
    link.href = event.detail.url
    link.dataset.confirm = true
    link.dataset.confirmTitle = locales[language].confirm.title
    link.dataset.confirmText = locales[language].confirm.text
    document.body.appendChild(link)

    if (!Confirm.allowAction(link)) {
      link.remove()
      Rails.stopEverything(event)
    }
  }

  setMultipartUpload() {
    this.uploader = new Uppy({
      autoProceed: true,
      allowMultipleUploads: false,
      locale: this.uppyLocale
    })

    this.uploader.use(StatusBar, {
      target: this.statusBarTarget,
      hideAfterFinish: false,
      showProgressDetails: true,
    })

    this.uploader.use(AwsS3Multipart, {
      companionUrl: '/uppy',
      companionHeaders: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    })

    this.uploader.on('upload-success', (file, response) => {
      const signedId = response.body.signed_id

      if (this.hasHiddenSignedInputTarget) {
        this.hiddenSignedInputTarget.value = signedId
      } else {
        let signedInput = document.createElement('input')
        signedInput.type = 'hidden'
        signedInput.name = this.hiddenInputTarget.name
        signedInput.value = signedId
        signedInput.setAttribute('data-inputs--file-target', 'hiddenSignedInput')
        this.hiddenInputTarget.insertAdjacentElement('beforebegin', signedInput)
      }
    })

    this.uploader.on('error', (error) => {
      if (this.hasHiddenSignedInput) this.hiddenSignedInputTarget.remove()
      this.uploadEnded()
    })

    this.uploader.on('file-removed', () => {
      this.hiddenInputTarget.value = null
    })

    this.uploader.on('complete', () => {
      this.hiddenInputTarget.value = null
      this.uploadEnded()
    })

    this.uploader.on('cancel-all', () => {
      this.uploadEnded()
    })

    this.uploader.on('restriction-failed', (file, error) => {
      this.uploadEnded()
    })

    this.hiddenInputTarget.addEventListener('change', (event) => {
      const files = Array.from(event.target.files)

      this.hiddenInputTarget.disabled = true
      document.addEventListener('turbo:before-visit', this.displayConfirm)

      files.forEach((file) => {
        try {
          this.uploader.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
            meta: {
              size: file.size,
              checksum: '=='
            }
          })
        } catch (err) {
          if (err.isRestriction) {
            // handle restrictions
            console.log('Restriction error:', err)
          } else {
            // handle other errors
            console.error(err)
          }
        }
      })
    })
  }

  uploadEnded() {
    this.hiddenInputTarget.disabled = false
    document.removeEventListener('turbo:before-visit', this.displayConfirm)
  }

  /* ----- GETTERS / SETTERS ----- */

  get type() {
    if (this.hiddenInputTarget.hasAttribute('multiple')) {
      return 'multiple'
    } else {
      return 'single'
    }
  }

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      en: EN,
      fr: FR
    }
  }

  get uppyLocale() {
    return this.uppyLocales[language]
  }

  get uppyLocales() {
    return {
      fr: French
    }
  }
}
