const FR = {
  confirm: {
    text: 'Un fichier est en cours de téléversement. Quitter la page annulera l\'envoi du fichier.',
    title: 'Êtes-vous sûr ?'
  },
  selected_file: 'fichier sélectionné',
  selected_files: 'fichiers sélectionnés',
}

export { FR }
