import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { post } from '../../utils/ajax'

export default class extends Controller {
  static values = { url: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.setup()
  }

  /* ----- FUNCTIONS ----- */

  setup() {
    this.sortable = new Sortable(this.element, this.options)
  }

  sort(event) {
    if (this.hasUrlValue) {
      post(this.urlValue, {
        order: this.sortable.toArray()
      })
    }
  }

  /* ----- GETTERS / SETTERS ----- */

  get options() {
    return {
      onSort: event => this.sort(event),
      handle: '[data-sortable-handle]'
    }
  }
}
