import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import debounce from 'lodash.debounce'

/**
 * Stimulus integration with Rails UJS. Use this controller to handle HTML
 * rendered responses from rails controllers.
 * @extends Controller
 */
export default class RemoteController extends Controller {
  connect () {
    this.debounceTime = parseInt(this.data.get('debounceTime') || 300)
    this.debouncedSubmit = debounce(this.submit, this.debounceTime)
  }

  /**
   * Submits a Rails remote form
   * @param {Event} event - an event with a currentTarget DOMElement
   */
  submit (event) {
    Rails.fire(event.target.form, 'submit')
  }

  /**
   * Appends the HTML Fragment from the response to the response target
   * @param {Event} event - a Rails UJS response event
   */
  append (event) {
    this._handleResponse(event, (fragment) => {
      this._responseTarget(event).append(fragment)
    })
  }

  /**
   * Prepends the HTML Fragment from the response to the response target
   * @param {Event} event - a Rails UJS response event
   */
  prepend (event) {
    this._handleResponse(event, (fragment) => {
      this._responseTarget(event).prepend(fragment)
    })
  }

  /**
   * Replaces the response target with the HTML Fragment from the response
   * @param {Event} event - a Rails UJS response event
   */
  replace (event) {
    this._handleResponse(event, (fragment) => {
      this._responseTarget(event).replaceWith(fragment)
    })
  }

  /**
   * Replaces the inside of the response target with the HTML Fragment from the response
   * @param {Event} event - a Rails UJS response event
   */
  replaceInner (event) {
    this._handleResponse(event, (fragment) => {
      const responseTarget = this._responseTarget(event)
      responseTarget.innerHTML = ''
      responseTarget.appendChild(fragment)
    })
  }

  /**
   * Removes the response target
   * @param {Event} event - a Rails UJS response event
   */
  remove (event) {
    this._handleResponse(event, (fragment) => {
      this._responseTarget(event).remove()
    })
  }

  // Private methods

  /**
   *
   * @param {Event} event - a Rails UJS response event
   * @param {Function} callback - a function taking a fragment parameter
   */
  _handleResponse (event, callback) {
    const [, , xhr] = event.detail
    const fragment = this._documentFragment(xhr.response)
    this._processScripts(fragment)
    callback(fragment)
  }

  /**
   * @private
   * @param {(string|DocumentFragment)} data
   * @returns DocumentFragment
   */
  _documentFragment (data) {
    const el = document.createElement('template')
    el.innerHTML = data
    return el.content
  }

  /**
   * @private
   * @param {NodeList} scripts
   */
  _processScripts (fragment) {
    if (!this.data.has('loadScripts')) return

    for (const script of fragment.querySelectorAll('script')) { eval(script.innerHTML) } // eslint-disable-line no-eval
  }

  _responseTarget (event) {
    let responseTarget = this.element

    if (this.data.has('responseTarget')) {
      const targetString = this.data.get('responseTarget')

      try {
        const object = JSON.parse(targetString)
        if (object[event.type] !== undefined) { responseTarget = document.querySelector(object[event.type]) }
      } catch {
        responseTarget = document.querySelector(targetString)
      }
    }

    return responseTarget
  }
}

export { RemoteController }
