import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['row']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  handleToggleRows(event) {
    this.toggleRows(event.params.row)
  }

  /* ----- FUNCTIONS ----- */

  toggleRows(id) {
    this.rowTargets.forEach(row => {
      if (row.dataset.id === id) {
        row.classList.toggle('hidden')
      }
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}
