import { Controller } from '@hotwired/stimulus'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { focusableSelector, isVisible } from '../../utils/application'

export default class extends Controller {
  static classes = ['current']
  static values = {
    focus: { type: Boolean, default: false },
    scroll: { type: Boolean, default: true },
    duration: { type: Number, default: 1 },
    easing: { type: String, default: 'power0' },
    offset: { type: Number, default: 20 },
    targetId: String
  }

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    gsap.registerPlugin(ScrollToPlugin)
  }

  connect() {
    if (window.location.hash === this.targetId) {
      this.scrollToTarget()
    }
  }

  /* ----- ACTION EVENTS ----- */

  onAjaxComplete(event) {
    if (this.focusValue) this.focusOnTarget()
    if (this.scrollValue) this.scrollToTarget()
  }

  onClick(event) {
    event.preventDefault()

    if (this.focusValue) this.focusOnTarget()
    if (this.scrollValue) this.scrollToTarget()
  }

  onKeyUp(event) {
    event.preventDefault()

    if (event.keyCode === 13 || event.keyCode === 32) {
      if (this.focusValue) this.focusOnTarget()
      if (this.scrollValue) this.scrollToTarget()
    }
  }

  /* ----- FUNCTIONS ----- */

  focusOnTarget(target = this.target) {
    const firstFocusableItem = target.querySelector(focusableSelector)

    if (firstFocusableItem) firstFocusableItem.focus()
  }

  scrollToTarget(targetId = this.targetId) {
    let offset = this.offsetValue

    offset += 68 // Header

    gsap.to(window, {
      duration: this.durationValue,
      scrollTo: {
        y: targetId,
        offsetY: offset,
        ease: this.easingValue
      }
    })
  }

  /* ----- GETTERS / SETTERS ----- */

  get targetId() {
    return this.hasTargetIdValue ? this.targetIdValue : this.element.hash
  }

  get target() {
    return document.querySelector(this.targetId)
  }
}
